@use "../../common/mixin/adaptiv";

.home-page-wrapper {
  display: flex;
  flex-direction: column;
  background: #FCFCFC;
  height: 100vh;
  padding: 0px;
  margin: 0px;
  z-index: 0;

}

.first-background {
  display: flex;
  background: rgba(242, 247, 255, 0.7);
  clip-path: polygon(0% 0%, 100% 0%, 100% 80%, 0% 100%);
  backdrop-filter: blur(66.6667px);
  height: 100%;
  width: 100%;
  border-bottom: 25px solid transparent;
  position: absolute;
  z-index: 1;
  max-height: 790px;
}

.content-home-page {
  margin-top: 160px;
  z-index: 3;
}

.home-title {
  margin-top: 56px;
  font-family: 'SF UI Text';
  font-style: normal;
  font-weight: 700 !important;
  @include adaptiv.adaptiv-font(62, 30);
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #0C2E42;
}

.home-sub-title-wrapper {
  width: 100%;
  text-align: center;
}

.home-sub-title {
  margin-top: 20px;
  font-family: 'SF UI Text';
  font-style: normal;
  font-weight: 500;
  @include adaptiv.adaptiv-font(28, 14);
  line-height: 140%;
  padding: 0 200px;

  text-align: center;
  letter-spacing: 0.02em;

  color: #1E2432;
}

.calculator-wrapper {
  padding: 0 390px 0px 390px;
}

.first-ellipse {
  z-index: 2;
  position: absolute;
  width: 15%;
  right: 0px;
  top: 80%;
  transform: translateY(-50%);
}


@media (max-width: 1200px) {
  .calculator-wrapper {
    padding: 0 200px 0px 200px;
  }
}

@media (max-width: 1000px) {
  //.first-ellipse {
  //  top: 50%;
  //}
}

@media (max-width: 800px) {
  .calculator-wrapper {
    padding: 0 10px 0px 100px;
  }

  .home-title {
    margin-top: 30px;
  }
  .home-sub-title {
    padding: 0 50px;
  }

  //.first-ellipse {
  //  top: 48%;
  //}
}

@media (max-width: 500px) {
  .first-background {
    clip-path: polygon(0% 0%, 100% 0%, 100% 60%, 0% 80%);
  }

  .calculator-wrapper {
    padding: 0 10px 0px 10px;
  }
  .home-title {
    margin-top: 30px;
  }
  .home-sub-title {
    padding: 0 18px;
  }

  .first-ellipse {
    width: 30%;
    top: 60%;
  }

}
