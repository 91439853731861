.root {
  display: flex;

  & > div {
    width: 50%;
  }
}
.selected {
  background-color: #FFF5E5 !important;
}
