.responsibles {
  &__item {
    max-width: 700px;
    margin: 0 0 15px 0;
    display: flex;
    align-items: center;
  }
  &__input {
    max-width: 550px;
    width: 100%;
    margin: 0 12px 0 0;
    padding: 12px 14px;
    background: #ffffff;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    &:focus {
      outline: 1px solid #ff9900;
    }
  }
  &__delete {
    cursor: pointer;
  }
  &__add {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #ff9900;
    background: transparent;
    &_plus {
      color: #ff9900;
      font-size: 20px;
      position: relative;
      top: 1px;
    }
  }
}
