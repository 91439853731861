.root {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #1E2432;
  display: flex;

  & > svg {
    margin-left: 5px;
    margin-top: -4px;
  }
}
