.root {
  display: flex;
  gap: 25px;
  flex-direction: column;

  .title {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 140%;
    text-align: center;
    letter-spacing: 0.02em;
    color: #1e2432;
  }

  .form {
    display: flex;
    gap: 8px;
    justify-content: flex-end;
    margin-bottom: 20px;
    width: 100%;
    @media (max-width: 500px) {
      padding-left: 20px;
    }
  }
}
