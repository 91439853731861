@import '../../../variables.scss';

.modalWrapper {
  z-index: 1;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  transition: 0.3s;
  background-color: $modal-wrapper-background;

  &--close {
    transition: 0.3s;
    background-color: transparent;
  }
}

.modal {
  z-index: 150;
  position: fixed;
  width: 95%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 10px;
  transform: scale(0);
  pointer-events: none;
  border-radius: 20px;

  &--open {
    background-color: white;
    transition: 0.3s ease 0s;
    overflow: auto;
    opacity: 1;
    pointer-events: auto;
    transform: scale(1);
  }

  &--close {
    opacity: 0;
    transition: 0.3s;
  }

  &__close {
    z-index: 150;
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
  }

  &__closeIcon {
    height: 15px;
  }

  &__section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:last-child {
      margin-bottom: 34px;
    }
  }

  &__line {
    height: 1px;
    margin-top: 22px;
    width: 100%;
    background-color: #ebebeb;
  }

  &__heading {
    margin-top: 20px;
    font-size: 1.625rem;
    font-weight: bold;
    text-align: center;
  }

  &__phones {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1rem;
    color: $project-color;
  }

  &__phone {
    height: 22px;
    display: flex;
    align-items: center;
  }

  &__tel {
    display: inline-block;
    margin-left: 10px;
    font-weight: bold;
    color: $project-color
  }
  
  &__contacts {
    margin-top: 25px;
    display: flex;
  }
  
  &__contact {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 20px;
    transition: 0.3s;
    background-color: transparent;
  }
  
  &__img {
    width: 36px;
    height: 36px;
  }
  
  &__contact:last-child {
    margin-right: 0;
  }
  
  &__contact:hover {
    transform: scale(1.1);
    transition: 0.3s;
  }
  
  &__tel:first-child {
    margin-top: 25px;
  }
}
