.root {
  .select {
    background-color: #fff;

    & > div {
      border-radius: 0;
      border: none;
      box-shadow: inset 0px -1px 0px #F0F0F0;
    }

    fieldset {
      border: none;
    }
  }

  label {
    transition: none;
    transform: none;
    top: unset;
    left: unset;
    padding: 16.5px 14px;
    color: rgba(0, 0, 0, 0.6) !important;
  }

}
