.root {
  font-family: 'Plus Jakarta Sans', sans-serif;
  color: #1e2432;
  padding: 120px 0 30px 0;
  min-height: 77vh;
  background: rgba(242, 247, 255, 0.7);
  backdrop-filter: blur(66.6667px);
  &__container {
    max-width: 1360px;
    margin: 0 auto;
    padding: 0 20px;
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  h2 {
    font-weight: 600;
    font-size: 26px;
    line-height: 140%;
    letter-spacing: 0.02em;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .formContent {
    width: 100%;
    max-width: 656px;
    align-self: center;
    .items {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      padding-bottom: 78px;

      .itemsRow {
        display: flex;
        flex-direction: row;
        gap: 40px;
      }
    }
  }

  .actions {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 40px;
  }

  @media (max-width: 500px) {
    & {
      min-height: 75vh;
      padding: 100px 15px 20px 15px;
    }

    h2 {
      font-size: 20px;
      line-height: 140%;
    }
  }
}
