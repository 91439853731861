.input-title {
  display: flex;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;

  span {
    color: #ff9900;
  }

  color: #777777;
  margin-right: 8px;
  margin-bottom: 10px;
}

.edit-img-wrapper {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  img {
    width: 28% !important;
  }
}

.edit-img-wrapper-v2 {
  @media (max-width: 600px) {
    margin: 0 0 0 10px;
  }
}
