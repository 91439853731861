.root {
  font-family: 'Plus Jakarta Sans', sans-serif;
  color: #1e2432;
  padding: 120px 15px 110px 15px;
  background: rgba(242, 247, 255, 0.7);
  backdrop-filter: blur(66.6667px);
  &__container {
    max-width: 1360px;
    margin: 0 auto;
    padding: 0 20px;
  }
  h2 {
    font-weight: 600;
    font-size: 26px;
    line-height: 140%;
    letter-spacing: 0.02em;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .note {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 20px;
    text-align: center;
  }

  form {
    margin-bottom: 36px;

    .formColumns {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 766px;
      margin: 0 auto;

      @media (max-width: 780px) {
        & {
          width: 480px;
        }
      }

      & > div > div label span:first-child {
        padding: 5px;
      }

      .col1 {
        width: 207px;

        div {
          padding-left: 41px;
          padding-top: 8px;
        }
      }

      .col2 {
        width: 160px;

        legend {
          padding-left: 40px;
        }

        div {
          padding-top: 8px;
        }
      }

      .col3 {
        width: 165px;

        div {
          padding-left: 22px;
          padding-top: 8px;
        }
      }

      svg {
        width: 18px;
        height: 18px;
      }
    }

    & .formColumns > div {
      width: 100%;
    }

    .content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-self: center;
      width: 100%;
      max-width: 900px;

      .col {
        display: flex;
        flex-direction: column;

        .radio {
          & svg {
            width: 18px;
            height: 18px;
          }
        }

        .item {
          display: flex;
          gap: 9px;
          font-size: 14px;

          & > * {
            height: 16px;
            display: block;
          }
        }
      }
    }

    .label {
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      color: #06437c;
      margin-bottom: 18px;
    }
  }

  .actions {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 40px;
  }

  .checkboxLabel {
    display: flex;
    gap: 8px;
    align-items: center;
    height: 26px;

    span {
      display: inline-block;
      line-height: 1;
      padding-top: 4px;
    }
  }

  .selectChannel {
    & > * {
      color: #06437c;
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 22px;
    }

    & > svg {
      width: 24px;
      height: 21px;
      top: calc(50% - 0.7em);
    }

    fieldset {
      border: none;
    }
  }

  @media (max-width: 500px) {
    & {
      min-height: 75vh;
      padding: 100px 15px 20px 15px;
    }

    h2 {
      font-size: 20px;
      line-height: 140%;
    }
  }
}

.itemMenu {
  font-family: 'Inter', sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  color: #565960 !important;
  min-height: 36px !important;

  & img {
    margin-right: 7px;
  }

  & > span {
    padding: 4px !important;
    margin-right: 2px;
  }
}

.checkboxMenu {
  & > svg {
    width: 18px;
    height: 18px;
  }
}

.listSubheader {
  font-weight: 500;
  font-size: 14px !important;
  line-height: 36px !important;
  color: #2d3243 !important;
  background: #fff3e9 !important;
  box-sizing: border-box;
  list-style: none;
  font-family: 'Inter', sans-serif;
  padding-left: 16px;
  padding-right: 16px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  min-height: 36px !important;
}

.selectAction {
  display: flex;
  justify-content: flex-end;
  padding: 8px 12px;
}

.selectedChannels {
  padding: 0 18px 20px;
  list-style: none;

  & li {
    display: flex;
    align-items: center;
    gap: 8px;
    padding-bottom: 8px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #06437c;
  }
}
