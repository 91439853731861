.root {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  color: #1E2432;
  line-height: 140%;
  padding-left: 25px;


  @media (max-width: 640px) {
    flex-direction: column;
    margin-top: -10px;
  }

  .robotImg {
    position: relative;
    top: -25px;
    width: 258px;
    height: 258px;

    &.showMobile {
      display: none !important;
    }

    @media (max-width: 640px) {
      display: none;

      &.showMobile {
        top: 0;
        width: 150px;
        display: inline-block !important;
      }
    }
  }

  .content {
    width: 100%;
    padding-left: 35px;

    @media (max-width: 640px) {
      padding-left: 0;
      margin: auto;
      width: 85%;
      display: flex;
      flex-direction: column;
      & button {
        max-width: 124px;
        align-self: flex-start;
      }
    }

    .title {
      max-width: 300px;
      font-weight: 600;
      font-size: 26px;
      line-height: 33px;
      text-align: left;
      letter-spacing: 0.02em;
      margin-bottom: 20px;
    }

    .text {
      font-weight: 500;
      font-size: 16px;
      text-align: left;
      letter-spacing: 0.02em;
      margin-bottom: 70px;
    }

    @media (max-width: 500px) {
      .title {
        font-size: 20px;
        line-height: 28px;
      }

      .text {
        margin-bottom: 33px;
      }
    }
  }
}
