.root {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 92%;
  max-width: 864px;
  min-height: 345px;
  margin: 20px auto;
  background: #FFFFFF;
  box-shadow: 0 4px 30px rgba(59, 84, 122, 0.2);
  border-radius: 10px;

  .close {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 8px;
  }

  .content {
    padding: 15px;
  }

  .ellipseTop {
    position: absolute;
    width: 15%;
    left: 0;
    top: 0;
    transform: rotate(180deg);
  }

  .ellipseBottom {
    position: absolute;
    width: 15%;
    right: 0;
    bottom: 0;
  }

  @media (max-width: 500px) {
    .ellipseTop {
      width: 35%;
    }
    .ellipseBottom {
      display: none;
    }
  }
}
