@use "../../common/mixin/adaptiv";

.info-block {
  background: #F5F9FE;
  width: 100%;
  padding: 30px 115px;
  margin-top: 120px !important;
  position: relative;

  .info-block-left {
    margin-right: 60px;
  }

  .info-block-right {
    margin-left: 60px;
  }

  .info-block-title {
    @include adaptiv.adaptiv-font(40, 30);
    font-family: 'SF UI Text';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;

    letter-spacing: -0.5px;

    color: #0C2E42;
  }

  .left-block-content {
    font-family: 'SF UI Text';
    font-style: normal;
    font-weight: 500;
    @include adaptiv.adaptiv-font(20, 14);
    line-height: 27px;
    letter-spacing: -0.5px;
    color: #1E2432;
  }

  .left-block-content {
    margin-top: 80px;
  }

  ul {
    list-style: none;
  }

  .info-block-list {
    li::before {
      content: "\2022";
      color: #FF9900;
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }

    li {
      margin: 15px 0;
      font-family: 'SF UI Text';
      font-style: normal;
      font-weight: 700;
      //font-size: 20px;
      @include adaptiv.adaptiv-font(20, 14);
      line-height: 27px;

      letter-spacing: -0.5px;

      color: #0C2E42;
    }
  }
}

.second-ellipse {
  position: absolute;
  width: 15%;
  left: 0;
  top: 0;
  transform: translateY(-50%);
}

@media (max-width: 1000px) {
  .info-block {
    padding: 30px 30px;
  }
}

@media (max-width: 900px) {
  .info-block {
    margin-top: 40px !important;
    padding-top: 40px !important;

    .info-block-left {
      margin-right: 0px;
    }

    .info-block-right {
      margin-left: 15px;
      margin-top: 45px;
    }

    .left-block-content {
      margin-top: 18px;
      text-align: center;
    }

    .info-block-title {
      text-align: center;
    }
  }
}

@media (max-width: 500px) {
  .second-ellipse {
    width: 30%;
  }
}