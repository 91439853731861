.root {
  flex-grow: 1;
  flex-shrink: 0;
  padding: 160px 15px 25px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 56px;
  background: #f2f7ffb2;
  margin-top: -15px;
  @media (max-width: 500px) {
    gap: 29px;
    z-index: 4;
    padding: 120px 15px 65px 15px;
  }

  .robotMob {
    width: 100%;

    svg {
      width: 50%;
    }
  }
}
