.root {
  display: flex;
  flex-direction: column;
  align-items: center;


  &:focus-visible {
    outline: none !important;
  }

  .iconCheck {
    width: 50px;
    margin-bottom: 20px;
  }

  .title {
    max-width: 50%;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 140%;
    text-align: center;
    letter-spacing: 0.02em;
    color: #1E2432;
    margin-bottom: 50px;
  }

  @media (max-width:500px) {
    .title {
      max-width: 90%;
      font-size: 20px;
      line-height: 140%;
    }
  }
}
