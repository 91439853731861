.root {
  display: flex;
  flex-direction: column;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  min-width: 360px;
  width: 100%;
  //&.hide {
  //  margin-top: -28px;
  //}

  .header {
    display: flex;
    gap: 7px;
    padding: 14px 17px;
    background: #ffebda;
    color: #ff9900;
    width: 100%;

    .icon {
      width: 14px;
      height: 14px;
    }

    .title {
      width: 100%;
    }
  }

  .actions {
    margin-top: 8px;
  }

  input,
  input:active,
  input:focus,
  input:focus-visible {
    width: 50%;
    background: #ffffff;
    box-shadow: inset 0px -1px 0px #f0f0f0;
    border: none !important;
    outline: none !important;
    padding: 12px;
    height: 40px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }

  input::placeholder {
    color: #b3b3b3;
  }

  .icon {
    filter: invert(63%) sepia(23%) saturate(4101%) hue-rotate(360deg) brightness(102%)
      contrast(102%);
  }

  .arrowBtn {
    padding: 0;
  }

  .arrowBtnHide {
    rotate: 180deg;
  }

  .hide {
    display: none;
  }

  @media (max-width: 500px) {
    & {
      min-width: 340px;
    }
  }
  &.cabinet {
    @media (max-width: 500px) {
      min-width: auto;
    }
  }
}

.inputRows {
  width: 100%;
}

.rootParams {
  display: flex;
  flex-direction: column;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  min-width: 360px;
  width: 100%;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  margin-top: 12px;
  padding: 18px;

  .header {
    display: flex;
    gap: 7px;
    padding: 0 0 13px;
    background: transparent;
    color: #242427;
    width: 100%;
    border-bottom: 1px solid #e9e9e9;
    margin-bottom: 16px;

    .icon {
      width: 14px;
      height: 14px;
    }

    .title {
      width: 100%;
      margin-left: 4px;
    }

    &.hide {
      padding: 0;
      border-bottom: none;
      margin-bottom: 0;
    }
  }

  .formRow {
    display: flex;
    gap: 12px;
    justify-content: space-between;
    align-items: baseline;
    &.cabinet {
      max-width: 580px;
    }
    & button {
      width: 20px;
      height: 20px;
    }

    & svg {
      width: 16px;
      height: 16px;
    }
  }

  input,
  input:active,
  input:focus,
  input:focus-visible {
    width: 100%;
    background: #fdfdfd;
    box-shadow: inset 0px -1px 0px #f0f0f0;
    outline: none !important;
    padding: 12px;
    height: 40px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    margin-bottom: 16px;
  }

  input::placeholder {
    color: #b3b3b3;
  }

  @media (max-width: 500px) {
    & {
      min-width: 340px;
    }
  }
  &.cabinet {
    @media (max-width: 500px) {
      min-width: auto;
    }
  }
}

.cabinet_input {
  max-width: 550px;
  background: #ffffff !important;
  border: 1px solid #e9e9e9 !important;
  border-radius: 4px;
}
