.base-url-wrapper {
  margin-top: 25px;
  position: relative;
  width: 100%;
  display: flex;
  padding-top: 0px!important;

  input:focus {
    outline: none;
    box-shadow: none;
    border: 2px solid #FF9900!important;
  }
}

.base-url-select {
  position: absolute;
  left: -100px;
  width: 100px;
}

.base-url-input-wrapper {
  width: 100%;
}

.base-input {
  width: 100%;
  height: 56px;
  padding-left: 20px;
  padding-right: 20px;

  font-family: SF UI Text!important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 138% */

  color: #3E424E;
  background: #FFFFFF;
  border: 2px solid #06437C !important;
  border-shadow: none;
  border-radius: 4px;

}

.base-input::placeholder {
  font-family: 'SF UI Text';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;

  color: #B3B3B3;
}



//.base-input:focus {
//  outline: none;
//  box-shadow: none;
//  //border: 1px solid #FF9900;
//}

@media (max-width: 500px) {
  .base-url-select {
    position: relative;
    width: 100px;
    left: 0px;
  }
  .base-url-input-wrapper {
    width: 80%;
  }
}
