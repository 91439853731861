.root {
  display: flex;
  justify-content: flex-end;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  width: 100%;
  .input {
    max-width: 326px;
    height: 40px;
    background: #ffffff;
    border: 1px solid #efefef;
    border-radius: 4px 0 0 4px;
    padding: 12px;
    font-family: inherit;
    font-style: inherit;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    flex: 0 1 90%;
  }

  .btn {
    height: 40px;
    width: 76px;
    border: 1px solid #ff9900;
    border-radius: 0 4px 4px 0;
    text-transform: none;
    background: #ff9900;
    color: #3b3b3b;
    font-family: inherit;
    font-style: inherit;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;

    &:hover,
    &:active {
      background: #ffb95a;
      border-color: #ffb95a;
    }

    &:disabled {
      background: #fde1b6;
      border-color: #fde1b6;
    }
  }
}
