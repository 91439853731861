.root {
  font-family: 'Plus Jakarta Sans', sans-serif;
  color: #1e2432;
  padding: 120px 0 0 0;
  min-height: 77vh;
  background: rgba(242, 247, 255, 0.7);
  backdrop-filter: blur(66.6667px);
  &__container {
    max-width: 1360px;
    margin: 0 auto;
    padding: 0 20px;
  }
  h2 {
    font-weight: 600;
    font-size: 26px;
    line-height: 140%;
    letter-spacing: 0.02em;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    padding-bottom: 78px;
    width: 100%;
    .table {
      width: 100%;
      max-width: 656px;
      align-self: center;
      margin-bottom: 8px;

      .header {
        background: #ffebda;
        color: #ff9900;
        width: 100%;

        th {
          padding: 14px 17px;
          color: #ff9900;
          border: none;
        }
      }

      .body {
        td {
          padding: 0;
          border: none;

          & > * {
            width: 100%;
          }
        }
      }
    }

    .add {
      width: 100%;
      max-width: 600px;
    }
  }

  .actions {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 40px;
  }

  @media (max-width: 500px) {
    & {
      min-height: 75vh;
      padding: 100px 15px 20px 15px;
    }

    h2 {
      font-size: 20px;
      line-height: 140%;
    }
  }
}
