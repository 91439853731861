// colors

$project-color: #FF9900;
$hover-project-color: #FFB23F;
$main-text-color: #1E2432;
$button-text-color: #1B1B1B;
$line-color: #E1E1E1;
$input-border-color: #ADADAD;
$info-color: #757575;
$empty-img-color: #B3CFFA;
$post-footer-color: #FFF8ED;
$subscribe-bg-btn-color: #1E2432;
$footer-line-color: rgba(85, 95, 115, 0.6);
$copyright-color: rgba(230, 232, 237, 0.6);
$footer-lang-color: #AAADB5;
$list-item-hover-color: rgba(255, 153, 0, 0.1);
$modal-wrapper-background: rgba(0, 0, 0, 0.4);


// text weights

$regular: 400;
$medium: 500;
$large: 600;
$bold: 700;

// fonts

$button-font: 1.125rem;
$link-font: 1.125rem;
$input-font: 1.125rem;

// borders

$main-border-radius: 12px;
$button-border-radius: 4px;
$input-border-radius: 4px;

// width

$max-width: 1440px;
$desktop-width: 1024px;
$tablet-width: 768px;
$mobile-large: 425px;
$mobile-medium: 375px;
$mobile-small: 320px;
