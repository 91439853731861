@import '../../../variables.scss';

.lang {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 55px;

  @media (max-width: $desktop-width) {
    margin-right: 20px;
  }

  &__button {
    background-color: transparent;
  }

  &__arrowDown {
    margin-bottom: 2px;
    margin-left: 8px;
    transition: 0.2s;

    &--rotated {
      transition: 0.2s;
      transform: rotate(180deg);
    }
  }

  &__button {
    background-color: transparent;
    border: 0;
    padding: 0;
    margin-left: 10px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__dialog {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 40px;
    width: 130px;
    background-color: white;
    border-radius: $button-border-radius;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.11), 0px 1px 7px rgba(0, 0, 0, 0.02);

    span {
      font-weight: 600;
    }
  }

  &__link {
    width: 100%;
    padding: 5px 10px;
    transition: 0.2s;

    &:hover {
      transition: 0.2s;
      background-color: $list-item-hover-color;
    }

    &--selected {
      color: $project-color;
      background-color: $list-item-hover-color;
    }
  }
}
