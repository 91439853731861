.select {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #fa8c26;
  border: 1px solid #fa8c26;
  border-radius: 4px;
  background: transparent;
  padding: 11px 15px;
  transition: 0.3s all ease;
  &:hover {
    background: #fa8c26;
    color: #ffffff;
  }
  &.active {
    background: #fa8c26;
    color: #ffffff;
  }
}
