.root {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #1E2432;
  display: flex;
  position: relative;
  background: #FFFFFF;
  border-radius: 20px;
  filter: drop-shadow(0px 4px 4px rgba(36, 37, 58, 0.25));
  width: 198px;
  height: 88px;
  padding: 18px 30px 19px;

  &::after {
    content: "";
    position: absolute;
    display: block;
    width: 0;
    z-index: 1;
    border-style: solid;
    border-color: transparent #FFFFFF;
    border-width: 17px 0 17px 29px;
    top: 74%;
    right: -20px;
    margin-top: -20px;
    border-radius: 3px;
    rotate: 120deg;
  }

  @media (max-width:500px) {
    & {
      margin-top: 50px;
      font-size: 16px;
      line-height: 16px;
      width: 160px;
      height: 80px;
    }
  }
}
