.root {
  display: flex;
  flex-direction: column;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  min-width: 360px;
  width: 100%;

  //&.hide {
  //  margin-top: -28px;
  //}

  .header {
    display: flex;
    gap: 7px;
    padding: 14px 17px;
    background: #FFEBDA;
    color: #FF9900;
    width: 100%;

    .icon {
      width: 14px;
      height: 14px;
    }

    .title {
      width: 100%;
    }

  }

  .actions {
    margin-top: 8px;
  }

  input, input:active, input:focus, input:focus-visible {
    width: 50%;
    background: #FFFFFF;
    box-shadow: inset 0px -1px 0px #F0F0F0;
    border: none !important;
    outline: none !important;
    padding: 12px;
    height: 40px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }

  input::placeholder {
    color: #B3B3B3;
  }

  .icon {
    filter: invert(63%) sepia(23%) saturate(4101%) hue-rotate(360deg) brightness(102%) contrast(102%);
  }

  .arrowBtn {
    padding: 0;
  }

  .arrowBtnHide {
    rotate: 180deg;
  }

  .hide {
    display: none;
  }

  @media (max-width: 500px) {
    & {
      min-width: 340px;
    }
  }
}

.inputRows {
  width: 100%;
}
