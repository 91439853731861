.root {
  width: 100%;
  height: 100%;
  max-width: 161px;
  max-height: 81px;
  min-height: 81px;
  margin: 12px;
  background: #F1F1F1;
  border-radius: 5px;
  cursor: pointer;

  .uploadImg {
    object-fit: cover;
    width: 100%;
    height: 100%;
    max-width: 161px;
    max-height: 81px;
    border-radius: 5px;
  }

  input {
    display: none;
  }
}
