.back {
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #3e424e;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  align-self: flex-start;
  margin: 20px 0 0 0;
  svg {
    margin: 0 7px 0 0;
  }
}
