.sites {
  margin-top: 16px;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  width: 100%;
  height: 202px;
  overflow-y: scroll;
  @media (max-width: 600px) {
    font-size: 18px;
    text-align: center;
    height: 150px;
  }
}

.title {
  @media (max-width: 600px) {
    font-size: 18px;
    text-align: center;
  }
}
