.utm-form-element-row {
  margin-top: 10px !important;
  font-family: 'Roboto', sans-serif;
}

.utm-select {
  width: 100px;
}

.base-url-grid-wrapper {
  width: 100%;
  padding-top: 0 !important;
}

@media (max-width: 500px) {
  .utm-form-element-row {
    padding-left: 40px !important;
    input {
      margin: 0 10px 0 0;
    }
  }

  .utm-form-dropdown-wrapper {
    padding-left: 0px !important;
    //height: 25px;
    padding-top: 0px !important;
    margin-top: 0px !important;
  }
}
