.input {
  background-color: #fff;
  & > div {
    border-radius: 0;
    border: none;
    box-shadow: inset 0px -1px 0px #F0F0F0;
  }
  fieldset {
    border: none;
  }
}
