.root {
  .loginBtn {
    height: 38px;
    border-radius: 30px;
    background: #54A9EB;
    text-transform: none;
    font-weight: 700;
    size: 12px;
    color: #fff;
  }
}
