@import '../../variables.scss';

.footer {
  font-family: 'Plus Jakarta Sans', sans-serif;
  margin-top: 16px;
  padding: 42px 120px;
  background-color: black;

  @media (max-width: $tablet-width) {
    padding: 14px 18px;
  }

  &__line {
    width: 100%;
    height: 1px;
    margin: 22px 0;
    background-color: $footer-line-color;
  }
}

.footerTop {
  font-family: 'Plus Jakarta Sans', sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__socialsLink {
    margin-right: 33px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__socialsImage {
    transition: 0.3s;
    opacity: 0.6;

    &:hover {
      transform: scale(1.1);
    }
  }
}

.footerBottom {
  font-family: 'Plus Jakarta Sans', sans-serif;
  display: flex;
  justify-content: space-between;

  a {
    text-decoration: none;
  }

  @media (max-width: $tablet-width) {
    flex-direction: column;
  }

  &__copyright {
    width: 90%;
    color: $copyright-color;

    @media (max-width: $tablet-width) {
      display: none;
    }
  }

  &__contacts {
    width: 100%;
    max-width: 650px;
    display: flex;
    justify-content: space-between;

    @media (max-width: $max-width) {
      flex-direction: column;
    }
  }

  &__location {
    width: 250px;
    margin-right: 10px;
    flex-direction: column;
    span {
      display: flex;
      align-items: center;
      margin: 0 0 5px 0;
    }
    @media (max-width: $max-width) {
      margin-bottom: 10px;
    }

    @media (max-width: $desktop-width) {
      width: 100%;
      margin-right: 0;
    }
  }

  &__location,
  &__phones {
    display: flex;
    align-items: flex-start;
    @media (max-width: $desktop-width) {
      // align-items: center;
    }
  }

  &__locationAddress {
    width: 100%;
    display: flex;
    flex-direction: column;
    color: white;
    font-size: 1.125rem;
    font-weight: $medium;

    @media (max-width: $max-width) {
      font-size: 1rem;
    }

    @media (max-width: $desktop-width) {
      font-size: 0.875rem;
      display: inline-block;
    }
  }

  &__phoneIcon {
    margin-top: 4px;
    margin-right: 13px;
  }
  &__locationIcon {
    margin-top: -3px;
    margin-right: 13px;
  }
  &__phone,
  &__phones {
    font-weight: 600;
    font-size: 1.125rem;
    color: white;

    @media (max-width: $max-width) {
      font-size: 1rem;
    }

    @media (max-width: $tablet-width) {
      font-size: 0.875rem;
    }
  }

  &__country {
    margin-bottom: 4px;

    @media (max-width: $desktop-width) {
      width: 100%;
    }
  }

  &__address {
    @media (max-width: $desktop-width) {
      width: 100%;
    }
  }

  &__langs {
    font-weight: 600;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 21px;
    color: $footer-lang-color;

    @media (max-width: $tablet-width) {
      margin-top: 20px;
      justify-content: flex-start;
    }
  }

  &__lang {
    font-size: 0.875rem;
    color: $footer-lang-color;

    &--selected {
      color: white;
    }
  }

  &__langDivider {
    margin: 0 5px;
    margin-top: 2px;
    width: 2px;
    height: 2px;
    background-color: $footer-lang-color;
  }
}
