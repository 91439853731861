.dropdown-wrapper {
  margin-top: 52px;
  padding-bottom: 0px;
  &.cabinet {
    margin-top: 0;
    max-width: 800px;
    @media (max-width: 800px) {
      display: flex;
      flex-direction: column;
      div {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}

.form-control {
  width: 100%;
}

.group-title {
  //background-color: rgba(255, 138, 38, 0.1)!important;
  //font-family: 'Inter'!important;
  //font-style: normal!important;
  //font-weight: 500!important;
  //font-size: 14px!important;
  //line-height: 16px!important;
  //color: #2D3243!important;
}

@media (max-width: 500px) {
  div.dropdown {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    & > div {
      width: 100%;
    }
  }

  .form-control {
    width: 180px;
  }
}
