.list {
  padding: 24px 7px !important;
  @media (max-width: 800px) {
    padding: 15px 0px !important;
  }
  span {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
  }

  .item {
    border-radius: 4px !important;
  }

  .itemSelected {
    background: rgba(255, 153, 0, 0.1) !important;

    span {
      color: #ff9900;
    }

    svg {
      color: #ff9900;
    }
  }

  .itemIcon {
    min-width: 30px;
  }
}
