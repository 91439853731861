.root {
  font-family: 'Plus Jakarta Sans', sans-serif;
  color: #1e2432;
  padding: 120px 0 0 0;
  min-height: 77vh;
  background: rgba(242, 247, 255, 0.7);
  backdrop-filter: blur(66.6667px);
  &__container {
    max-width: 1360px;
    margin: 0 auto;
    padding: 0 20px;
  }
  h2 {
    font-weight: 600;
    font-size: 26px;
    line-height: 140%;
    letter-spacing: 0.02em;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    padding-bottom: 78px;
    width: 100%;

    .form {
      width: 100%;

      .tableWrapper {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
      }
    }

    .table {
      width: 100%;
      max-width: 860px;

      .header {
        background: #ffebda;
        color: #ff9900;
        width: 100%;

        th {
          padding: 14px 17px;
          color: #ff9900;
          border: none;
        }
      }

      .body {
        td {
          vertical-align: top;
          padding: 0;
          border: none;
          background-color: #fff;
          box-shadow: inset 0px -1px 0px #e9e9e9;

          & > * {
            width: 100%;
          }

          & .channel {
            width: 170px;
          }

          & .type {
            width: 150px;
          }
        }
      }
    }

    .add {
      width: 100%;
      max-width: 860px;
      margin-top: 11px;
    }
  }

  .actions {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 40px;
  }

  .previewWrapper {
    width: 30%;
  }

  .preview {
    width: 100%;
    height: 100%;
    max-width: 161px;
    max-height: 81px;
    min-height: 81px;
    margin: 12px;
    background: #f1f1f1;
    border-radius: 5px;
  }

  .select {
    & > div {
      box-shadow: none;
    }
  }

  td.paramsCell {
    text-align: left;
  }

  @media (max-width: 500px) {
    & {
      min-height: 75vh;
      padding: 100px 15px 20px 15px;
    }

    h2 {
      font-size: 20px;
      line-height: 140%;
    }

    .select {
      & > div {
        padding: 10px;
      }
    }

    td.paramsCell {
      padding-left: 15px !important;

      & * {
        width: 100% !important;
      }
      & svg {
        width: auto !important;
      }
    }
  }
}
