.channels {
  &__title {
    padding: 0 0 30px 0;
  }
  &__selector {
    padding: 0 0 70px 0;
  }
  &__filters {
    border-top: 1px solid #e9e9e9;
    padding: 20px 0 0 0;
  }
  &__switch {
    padding: 40px 0 10px 0;
    border-bottom: 1px solid #e9e9e9;
    @media (max-width: 500px) {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      border: none;
    }
  }
  &__content {
    padding: 15px 0;
  }
}

.switch {
  &__item {
    cursor: pointer;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #828f9a;
    padding: 0 14px;
    @media (max-width: 500px) {
      margin: 0 0 20px 0;
      padding: 0;
    }
    &.active {
      position: relative;
      color: #fa8c26;
      &::before {
        content: '';
        position: absolute;
        top: 31px;
        left: 0;
        width: 140px;
        height: 2px;
        background: #fa8c26;
        border-radius: 6px;
        @media (max-width: 500px) {
          width: 100%;
        }
      }
    }
    &.utm {
      &.active {
        &::before {
          width: 65px;
          @media (max-width: 500px) {
            width: 100%;
          }
        }
      }
    }
    &.params {
      &.active {
        &::before {
          width: 118px;
          @media (max-width: 500px) {
            width: 100%;
          }
        }
      }
    }
  }
}
