.full-url-wrapper {
  display: flex;
  margin-top: 70px;
  width: 100%;
  justify-content: center;
  align-items: center;
  &.cabinet {
    justify-content: flex-start;
  }
}

.full-url-input-wrapper {
  width: 80%;
  &.cabinet {
    width: 50%;
    @media (max-width: 800px) {
      width: 100%;
    }
  }
}

.full-url-img-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.full-url-img {
  width: 35px;
  padding-left: 10px;
  transform: translateY(50%);
}

.full-url-input-text {
  font-family: 'SF UI Text';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 22px;
  margin-bottom: 8px;

  color: #06437c;
}

.full-input {
  width: 100%;
  height: 56px;
  padding-left: 20px;
  padding-right: 20px;

  //font-family: SF UI Text!important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 138% */

  color: #3e424e;
  background: #ffffff;
  border: 2px solid #06437c !important;
  border-shadow: none;
  border-radius: 4px;
}

.full-input::placeholder {
  font-family: 'SF UI Text';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;

  color: #b3b3b3;
}

.full-input:focus {
  outline: none;
  box-shadow: none;
  //border: 1px solid #FF9900;
}

@media (max-width: 800px) {
  .full-url-wrapper {
    margin-top: 15px;
  }
}
