.btn {
  font-family: 'Plus Jakarta Sans' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #FF9900 !important;
  text-transform: none !important;

  &:hover, &:active {
    text-decoration: none !important;
    background-color: rgba(253, 191, 6, 0.19) !important;
  }

  &:disabled {
    background-color: rgba(225, 225, 225, 0.15) !important;
    color: #ddd !important;
  }
}
