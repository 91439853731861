@use "../../common/mixin/adaptiv";

.links-wrapper {
  position: relative;
  background: #F5F9FE;
  width: 100%;
  padding: 100px 0px;
  text-align: center;

  .links-ellipse {
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 15%;
  }

  .links-title {
    font-family: 'SF UI Text';
    font-style: normal;
    font-weight: 700;
    @include adaptiv.adaptiv-font(40, 30);
    line-height: 60px;

    letter-spacing: -0.5px;

    color: #0C2E42;
  }

  .links-subtitle {
    font-family: 'SF UI Text';
    font-style: normal;
    font-weight: 500;
    //font-size: 20px;
    line-height: 140%;
    @include adaptiv.adaptiv-font(20, 14);


    text-align: center;
    letter-spacing: 0.02em;

    color: #1E2432;
  }

  .links {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;

    a {
      display: flex;
      justify-content: center!important;
    }

    .links-icon {
      width: 30px;
      margin-right: 19px;
      margin-left: 19px;
    }

    .links-icon-facebook {
      width: 18px;
    }
  }
}

@media (max-width: 500px) {
  .links-wrapper {
    padding: 40px 10px;
    .links-ellipse {
      width: 40%;
    }
  }

}