.root {
  display: flex;
  flex-direction: column;
  .title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    color: #242427;
    @media (max-width: 500px) {
      font-size: 24px;
      text-align: center;
    }
  }
  h2 {
    margin-top: 19px;
  }

  .filter {
    width: 100%;
    max-width: 800px;
    display: flex;
    align-items: center;
    gap: 41px;
    margin-top: 17px;
  }

  .sites {
    margin-top: 16px;
    border: 1px solid #e1e1e1;
    border-radius: 4px;
    width: 100%;
    height: 202px;
    overflow-y: scroll;
  }

  .formWrapper {
    margin-top: 27px;
    width: 100%;
  }
}
