@use "../../common/mixin/adaptiv";

.main-slider-wrapper {
  justify-content: center;
  align-items: center;
  font-family: SF UI Text;
  font-style: normal;
  font-weight: 700;
  @include adaptiv.adaptiv-font(40, 30);
  line-height: 60px;
  letter-spacing: -0.5px;
  color: #0C2E42;
  padding: 100px 112px;
}

.main-slider-content {
  margin-top: 35px;
}

.control-button-wrapper {
  display: flex;
  flex-direction: column !important;


}

.main-slider {
  box-shadow: 0px 4px 30px rgba(59, 84, 122, 0.2);
  border-radius: 10px;
  background: #FFFFFF;
  padding-right: 26px;
  padding-left: 26px;
}

.main-slider-item {
  display: flex;
  flex-direction: column;

  .slide-row {
    display: flex;
    padding: 25px 0px;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #ECECEC;

    &:last-child {
      border-bottom: none !important;
    }
  }

  .filed-name {
    font-family: 'SF UI Text';
    font-style: normal;
    font-weight: 700;
    @include adaptiv.adaptiv-font(16, 14);
    line-height: 22px;
    color: #FF9900;
  }

  .filed-comment {
    font-family: 'SF UI Text';
    font-style: normal;
    font-weight: 500;
    @include adaptiv.adaptiv-font(16, 14);
    line-height: 22px;
    color: #1E2432;
    text-align: left;
  }
}

.control-btn {
  display: flex;
  padding: 20px;
  align-items: center;
  width: 250px;
  height: 80px;
  box-shadow: 0px 4px 30px rgba(59, 84, 122, 0.2);
  border-radius: 10px;
  font-family: 'SF UI Text';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  cursor: pointer;
  margin-bottom: 20px;

  .control-btn-img-wrapper {
    background: #1E2432;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.25);
    width: 40px;
    height: 40px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 14px;
  }
}

.active-control-btn {
  border: 1px solid #FF9900;
}

.indicator-wrapper {
  display: flex;
  justify-content: center;
}

.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  background-color: #C2C2C2;
  margin: 0px 5px;
}

.active-dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  background-color: #FF9900;
  margin: 0px 5px;
}

@media (max-width: 1000px) {
  .main-slider-wrapper {
    padding: 30px 30px;
  }
}

@media (max-width: 900px) {
  .main-slider-wrapper {
    padding: 0px;
  }

  .main-slider-title {
    margin-top: 40px;

  }

  .main-slider-content {
    margin-top: 5px;
  }

  .main-slider {
    margin: 0px 30px;
    margin-top: 5px;
  }

  .main-slider-title {
    text-align: center;
  }

  .indicator-wrapper {
    margin-top: 18px;
  }

  .control-button-wrapper {
    block-size: max-content;
    flex-direction: row !important;
    width: 100%;
    max-width: 100%;
    overflow-y: hidden;
    overflow-x: scroll;
    padding-top: 10px;
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: content-box;
    scrollbar-width: 5px;
    scrollbar-color: rebeccapurple green;

  }

  .control-btn {
    margin-right: 12px;
    min-width: 180px;
  }
}

@media (max-width: 600px) {
  .filed-name {
    text-align: left;
  }
}