@import url("./assets/fonts/san-francisco/stylesheet.css");
@import url("./assets/fonts/inter/stylesheet.css");

* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}

html {
  background: #FCFCFC;
}


.clear-select {
  border: 0 !important;
  box-shadow: none !important;
}

::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: #e7e9ee;
  border-radius: 10px;
}