button.root {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #1B1B1B;
  background: #FF9900;
  border-radius: 4px;
  text-transform: none;
  min-width: 160px;
  height: 56px;
  border: none;

  &:hover, &:active {
    background: #ffb95a;
    border-color: #ffb95a;
  }

  &:disabled {
    background: #FDE1B6;
  }

  &.mobile {
    height: 56px;
    @media (max-width: 500px) {
      & {
        height: 40px !important;
      }
    }
  }
}
