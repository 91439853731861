@import '../../variables.scss';
@import '../../common/mixin/adaptiv.scss';

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

a {
  text-decoration: none;
  color: $main-text-color;
  font-weight: $large;
  font-size: $link-font;
}

button {
  background-color: $project-color;
  color: $button-text-color;
  border: 0;
  font-weight: $large;
  font-size: $button-font;
  border-radius: $button-border-radius;
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

.swiper-wrapper {
  display: flex;
}

.swiper-button-next,
.swiper-button-prev {
  position: absolute !important;
  top: -42px !important;

  &::after {
    font-size: 20px !important;
    font-weight: 900 !important;
    color: $project-color !important;

    @media (max-width: $mobile-large) {
      font-size: 14px !important;
    }
  }

  @media (max-width: $desktop-width) {
    top: -32px !important;
  }

  @media (max-width: $mobile-large) {
    top: -20px !important;
  }
}

.swiper-button-prev {
  left: auto !important;
  right: 50px !important;

  @media (max-width: $tablet-width) {
    right: 20px !important;
  }
}

.swiper-button-next {
  @media (max-width: $tablet-width) {
    right: -2px !important;
  }
}

.swiper {
  overflow: visible !important;
}

.noscroll {
  overflow: hidden;
}

.modal-open {
  overflow: hidden;
}

.header {
  font-family: 'Plus Jakarta Sans', sans-serif;
  z-index: 100;
  position: fixed;
  top: 0;
  width: 100%;
  padding-top: 26px;
  background-color: white;

  &__logo {
    @media (max-width: $tablet-width) {
      padding-left: 12px;
    }
  }

  @media (max-width: $desktop-width) {
    padding-top: 16px;
  }

  &__container {
    padding: 0 24px;

    @media (max-width: $tablet-width) {
      padding: 0;
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
  }

  &__line {
    width: 100%;
    height: 1px;
    margin-top: 26px;
    background-color: $line-color;

    @media (max-width: $desktop-width) {
      margin-top: 10px;
    }
  }

  &__actions {
    display: flex;
    align-items: center;

    @media (max-width: $tablet-width) {
      display: none;
    }
  }

  &__button {
    display: block;
    width: 210px;
    height: 56px;
    transition: 0.3s;
    font-size: 1rem;
    background: #ff9900;
    @media (max-width: $desktop-width) {
      width: 140px;
      height: 42px;
      font-size: 14px;
    }

    &:hover {
      transition: 0.3s;
      background-color: $hover-project-color;
    }
  }
  &__login {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
}

.nav {
  margin-right: 36px;

  @media (max-width: $desktop-width) {
    margin-right: 20px;
  }

  &__list {
    display: flex;
    align-items: center;
    list-style-type: none;
  }

  &__item {
    position: relative;
    height: 24px;
    line-height: 24px;
    margin-right: 40px;

    &:last-child {
      margin-right: 0;
    }

    @media (max-width: $desktop-width) {
      margin-right: 20px;
    }
  }

  &__link {
    background-color: transparent;

    &:hover {
      color: $project-color;
    }

    &--selected {
      color: $project-color;
    }
  }

  &__dropdownIcon {
    margin-left: 8px;
    margin-bottom: 2px;
    transition: 0.2s;

    &--selected {
      transform: 0.2s;
      transform: rotate(180deg);
    }
  }
}

.categories {
  position: relative;
  margin-right: 40px;

  @media (max-width: $desktop-width) {
    margin-right: 10px;
  }

  &__button {
    background-color: transparent;

    &--selected {
      color: $project-color;
    }

    &:hover {
      color: $project-color;
    }
  }

  &__arrow {
    margin-bottom: 2px;
    margin-left: 12px;
    transition: 0.2s;

    &--open {
      transition: 0.2s;
      transform: rotate(180deg);
    }
  }
}

.categoriesList {
  width: 260px;
  position: absolute;
  top: 35px;
  background-color: white;
  border-radius: $main-border-radius;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.11), 0px 1px 7px rgba(0, 0, 0, 0.02);

  &__item {
    width: 100%;
    height: 28px;

    &--selected {
      background-color: $list-item-hover-color;
    }
  }

  &__link {
    display: inline-block;
    width: 100%;
    padding-left: 10px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 28px;
    transition: 0.2s;

    &--selected {
      color: $project-color;
      cursor: auto;
    }

    &:hover {
      transition: 0.2s;
      color: $project-color;
    }
  }
}

.menu {
  margin-top: 59px;
  padding: 16px;
  position: fixed;
  display: none;
  right: -1000px;
  width: 100%;
  height: 100vh;
  transition: 0.5s;
  overflow: scroll;
  background-color: white;

  &__icon {
    margin-bottom: 2px;
    margin-left: 8px;
    transition: 0.2s;

    &--selected {
      transition: 0.2s;
      transform: rotate(180deg);
    }
  }

  @media (max-width: $tablet-width) {
    display: flex;
    flex-direction: column;
  }

  &--open {
    transition: 0.5s;
    right: 0;
  }

  &__button {
    display: block;
    margin-right: 18px;
    background-color: transparent;
    @media (min-width: 769px) {
      display: none;
    }
  }

  &__header {
    font-size: 1rem;
    font-weight: $regular;
    background-color: transparent;
    margin: 0;
    padding: 0;
    text-align: left;
    margin-bottom: 22px;

    &--selected {
      color: $project-color;
    }
  }

  &__line {
    width: 100%;
    height: 1px;
    margin: 22px 0;
    background-color: $line-color;
  }

  &__item {
    width: 100%;
    height: 28px;
    display: flex;
    align-items: center;
    transition: 0.2s;

    &:hover {
      transition: 0.2s;
      background-color: $list-item-hover-color;
    }
  }

  &__link {
    display: inline-block;
    font-size: 1rem;
    width: 100%;
    font-weight: $regular;
    margin-left: 12px;
  }

  &__langsButton {
    margin-top: 10px;
    background-color: transparent;
    border: 0;
    padding: 0;
    margin-right: 10px;
    margin-left: 10px;

    &--selected {
      color: $project-color;
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__contactUsButton {
    width: 210px;
    height: 42px;
    transition: 0.3s;
    font-size: 14px;
    margin-top: 46px;
    background: #ff9900;
    &:hover {
      transition: 0.3s;
      background-color: $hover-project-color;
    }
  }
  &__login {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
}
