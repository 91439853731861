@import '../../../variables.scss';

.dropdown {
  position: absolute;
  top: 35px;
  width: 250px;
  padding: 14px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.11), 0px 1px 7px rgba(0, 0, 0, 0.02);

  @media (max-width: $tablet-width) {
    position: relative;
    top: 0;
    width: 100%;
    border-radius: 0;
    box-shadow: none;
  }

  &__list {
    &:before{
      content: attr(aria-label);
      font-size: 1rem;
      line-height: 28px;
      font-weight: 600;
    }
  }

  &__item {
    margin-left: 28px;
    transition: 0.2s;

    &:hover {
      color: $project-color
    }
  }

  &__link {
    font-size: 1rem;
    font-weight: 400;
    line-height: 28px;

    &:hover {
      color: $project-color
    }

    &--selected {
      color: $project-color
    }
  }
}
